<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('damReport.comparative_market_price_statement') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Division" vid="division_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.division') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                  id="division_id"
                                  v-model="values_division"
                                  search
                                  historyButton
                                  :filters="filters_division"
                                  :options="options"
                                  :selectOptions="divisionList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="District" vid="district_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.district') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                  id="district_id"
                                  v-model="values_district"
                                  search
                                  historyButton
                                  :filters="filters_district"
                                  :options="options"
                                  :selectOptions="districtList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Upazila" vid="upazila_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.upazila') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                id="upazila_id"
                                v-model="values_upazila"
                                search
                                historyButton
                                :filters="filters_upazila"
                                :options="options"
                                :selectOptions="upazilaList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                                <div class="invalid-feedback d-block"   v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Market" vid="market_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="market_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('crop_price_config.market') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                id="market_id"
                                v-model="values_market"
                                search
                                historyButton
                                :filters="filters_market"
                                :options="options"
                                :selectOptions="marketList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Commodity Group" vid="commodity_group_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="commodity_group_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.commodity_group_name') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.commodity_group_id"
                                :options="groupList"
                                id="commodity_group_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                       </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Commodity Sub Group" vid="commodity_sub_group">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="commodity_sub_group_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.commodity_sub_group') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.commodity_sub_group_id"
                                :options="subgroupList"
                                id="commodity_sub_group_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Commodity Name" vid="commodity_name">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="commodity_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('crop_price_config.commodity_name')}}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.commodity_id"
                                :options="commodityNameList"
                                id="commodity_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Price Date" vid="price_date" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="price_date"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('crop_price_config.price_date')}} <span class="text-danger">*</span>
                          </template>
                              <b-form-input
                              class="datepicker"
                              v-model="search.price_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :placeholder="$t('crop_price_info.date_figure')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Price Type" vid="price_type_id"  rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="price_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.price_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="search.price_type_id"
                            :options="priceTypeList"
                            id="market_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2" @click="clickSubmit=true">{{ $t('globalTrans.search') }}</b-button>
                        &nbsp;
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay :show="loading">
              <iq-card v-if='givenDateReports.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('damReport.comparative_market_price_statement') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="headerValue"
                            :before-generate = "headerDataExcel"
                            worksheet="Report Sheet"
                            name="comparative-market-pricet.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="warehouseServiceBaseUrl" uri="/master-warehouse-report-heading/detail" :org-id="13">
                                         <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('damReport.comparative_market_price_statement') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;">
                                          <tr>
                                            <td class="align-top" align="right" width="20%">{{ $t('globalTrans.division') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" width="75%" >{{ getDivisionName(search.division_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.district') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getDistrictName(search.district_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.upazila') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getUpazilaNameCustom(search.upazila_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.market') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getMarketName(search.market_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_group_name') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left" style="width:10%">{{ ($i18n.locale==='bn') ? search.commodity_group_name_bn : search.commodity_group_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_sub_group') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? search.commodity_sub_group_name_bn : search.commodity_sub_group_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_name') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? search.commodity_name_bn : search.commodity_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.price_type') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getPriceTypeName(search.price_type_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.price_date') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ search.price_date | dateFormat }}</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <!-- <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:20%" class="text-center">{{ $t('damReport.product_name_and_description') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.measurement') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.today_market_rate') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.previus_week_market_rate') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.percentage_decrease_increase') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.previus_month_market_rate') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.percentage_decrease_increase') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.previus_year_market_rate') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.percentage_decrease_increase') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in givenDateReports" :key="index">
                                          <b-td class="text-center">
                                            {{ ($i18n.locale === 'bn') ? info.commodity_name_bn : info.commodity_name }}
                                          </b-td>
                                          <b-td class="text-center">
                                            {{ ($i18n.locale === 'bn') ? info.commodity.unit_retail.unit_name_bn : info.commodity.unit_retail.unit_name }}
                                          </b-td>
                                          <b-td class="text-center">
                                            <template v-if="search.price_type_id === 'Wholesale'">
                                              <template v-if="info.w_lowestPrice && info.w_highestPrice">
                                                {{ $n(parseFloat(info.w_lowestPrice).toFixed(2)) }} -
                                                {{ $n(parseFloat(info.w_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                            <template v-else>
                                              <template v-if="info.r_lowestPrice && info.r_highestPrice">
                                                {{ $n(parseFloat(info.r_lowestPrice).toFixed(2)) }} -
                                                {{ $n(parseFloat(info.r_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                          </b-td>
                                          <b-td class="text-center">
                                            <template v-if="search.price_type_id === 'Wholesale'">
                                              <template v-if="info.weak_w_lowestPrice && info.weak_w_highestPrice">
                                                {{ $n(parseFloat(info.weak_w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.weak_w_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                            <template v-else>
                                              <template v-if="info.weak_r_lowestPrice && info.weak_r_highestPrice">
                                                {{ $n(parseFloat(info.weak_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.weak_r_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                          </b-td>
                                          <b-td class="text-center">
                                            <template v-if="search.price_type_id === 'Wholesale'">
                                              <template v-if="info.w_avgPrice && info.weak_w_avgPrice">
                                                {{$n(parseFloat(calculatePercentage(info.w_avgPrice,info.weak_w_avgPrice)).toFixed(2))}}
                                              </template>
                                            </template>
                                            <template v-else>
                                              <template v-if="info.r_avgPrice && info.weak_r_avgPrice">
                                                {{$n(parseFloat(calculatePercentage(info.r_avgPrice,info.weak_r_avgPrice)).toFixed(2))}}
                                              </template>
                                            </template>
                                          </b-td>
                                          <b-td class="text-center">
                                            <template v-if="search.price_type_id === 'Wholesale'">
                                              <template v-if="info.month_w_lowestPrice && info.month_w_highestPrice">
                                                {{ $n(parseFloat(info.month_w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.month_w_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                            <template v-else>
                                              <template v-if="info.month_r_lowestPrice && info.month_r_highestPrice">
                                                {{ $n(parseFloat(info.month_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.month_r_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                          </b-td>
                                          <b-td class="text-center">
                                            <template v-if="search.price_type_id === 'Wholesale'">
                                              <template v-if="info.w_avgPrice && info.month_w_avgPrice">
                                                {{$n(parseFloat(calculatePercentage(info.w_avgPrice, info.month_w_avgPrice)).toFixed(2))}}
                                              </template>
                                            </template>
                                            <template v-else>
                                              <template v-if="info.r_avgPrice && info.month_r_avgPrice">
                                                {{$n(parseFloat(calculatePercentage(info.r_avgPrice, info.month_r_avgPrice)).toFixed(2))}}
                                              </template>
                                            </template>
                                          </b-td>
                                          <b-td class="text-center">
                                            <template v-if="search.price_type_id === 'Wholesale'">
                                              <template v-if="info.year_w_lowestPrice && info.year_w_highestPrice">
                                                {{ $n(parseFloat(info.year_w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.year_w_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                            <template v-else>
                                              <template v-if="info.year_r_lowestPrice && info.year_r_highestPrice">
                                                {{ $n(parseFloat(info.year_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.year_r_highestPrice).toFixed(2)) }}
                                              </template>
                                            </template>
                                          </b-td>
                                          <b-td class="text-center">
                                            <template v-if="search.price_type_id === 'Wholesale'">
                                              <template v-if="info.w_avgPrice && info.year_w_avgPrice">
                                                {{$n(parseFloat(calculatePercentage(info.w_avgPrice,info.year_w_avgPrice)).toFixed(2))}}
                                              </template>
                                            </template>
                                            <template v-else>
                                              <template v-if="info.r_avgPrice && info.year_r_avgPrice">
                                                {{$n(parseFloat(calculatePercentage(info.r_avgPrice,info.year_r_avgPrice)).toFixed(2))}}
                                              </template>
                                            </template>
                                          </b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row> -->
                                  <b-row>
                                    <b-col sm="12" class="table-responsive steaky-header">
                                      <slot>
                                        <b-table head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="givenDateReports" :fields="columns">
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(unit_name)="data">
                                            {{ data.item.unit_name }}
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(unit_name_bn)="data">
                                            {{ data.item.unit_name_bn }}
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(w_unit_name)="data">
                                            {{ data.item.w_unit_name }}
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(w_unit_name_bn)="data">
                                            {{ data.item.w_unit_name_bn }}
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(w_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.w_lowestPrice) || parseFloat(data.item.w_highestPrice)">
                                              {{ $n(parseFloat(data.item.w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.w_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(r_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.r_lowestPrice) || parseFloat(data.item.r_highestPrice)">
                                                {{ $n(parseFloat(data.item.r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.r_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(weak_w_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.weak_w_lowestPrice) || parseFloat(data.item.weak_w_highestPrice)">
                                               {{ $n(parseFloat(data.item.weak_w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.weak_w_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(weak_r_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.weak_r_lowestPrice) || parseFloat(data.item.weak_r_highestPrice)">
                                                {{ $n(parseFloat(data.item.weak_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.weak_r_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(weak_w_avgPrice)="data">
                                            <template v-if="parseFloat(data.item.w_avgPrice) && parseFloat(data.item.weak_w_avgPrice)">
                                                {{$n(parseFloat(calculatePercentage(data.item.w_avgPrice,data.item.weak_w_avgPrice)).toFixed(2))}}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(weak_r_avgPrice)="data">
                                            <template v-if="parseFloat(data.item.r_avgPrice) && parseFloat(data.item.weak_r_avgPrice)">
                                                {{$n(parseFloat(calculatePercentage(data.item.r_avgPrice,data.item.weak_r_avgPrice)).toFixed(2))}}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(month_w_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.month_w_lowestPrice) || parseFloat(data.item.month_w_highestPrice)">
                                                {{ $n(parseFloat(data.item.month_w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.month_w_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(month_r_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.month_r_lowestPrice) || parseFloat(data.item.month_r_highestPrice)">
                                                {{ $n(parseFloat(data.item.month_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.month_r_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(month_w_avgPrice)="data">
                                            <template v-if="parseFloat(data.item.w_avgPrice) && parseFloat(data.item.month_w_avgPrice)">
                                                {{$n(parseFloat(calculatePercentage(data.item.w_avgPrice, data.item.month_w_avgPrice)).toFixed(2))}}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(month_r_avgPrice)="data">
                                            <template v-if="parseFloat(data.item.r_avgPrice) && parseFloat(data.item.month_r_avgPrice)">
                                                {{$n(parseFloat(calculatePercentage(data.item.r_avgPrice, data.item.month_r_avgPrice)).toFixed(2))}}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(year_w_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.year_w_lowestPrice) || parseFloat(data.item.year_w_highestPrice)">
                                                {{ $n(parseFloat(data.item.year_w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.year_w_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(year_r_lowestPrice)="data">
                                            <template v-if="parseFloat(data.item.year_r_lowestPrice) || parseFloat(data.item.year_r_highestPrice)">
                                                {{ $n(parseFloat(data.item.year_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(data.item.year_r_highestPrice).toFixed(2)) }}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Wholesale'" v-slot:cell(year_w_avgPrice)="data">
                                            <template v-if="parseFloat(data.item.w_avgPrice) && parseFloat(data.item.year_w_avgPrice)">
                                                {{$n(parseFloat(calculatePercentage(data.item.w_avgPrice,data.item.year_w_avgPrice)).toFixed(2))}}
                                            </template>
                                          </template>
                                          <template v-if="search.price_type_id === 'Retail'" v-slot:cell(year_r_avgPrice)="data">
                                            <template v-if="parseFloat(data.item.r_avgPrice) && parseFloat(data.item.year_r_avgPrice)">
                                                {{$n(parseFloat(calculatePercentage(data.item.r_avgPrice,data.item.year_r_avgPrice)).toFixed(2))}}
                                            </template>
                                          </template>
                                        </b-table>
                                        </slot>
                                    </b-col>
                                </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger" v-if="!firstTime">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import Store from '@/store'
import moment from 'moment'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { dateFormat } from '@/Utils/fliter'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead,
        vueMultiSelect
    },
    created () {
        // if (this.id) {
        //     const tmp = this.getEditingData()
        //     this.search = tmp
        // }
    },
    data () {
        return {
          btnLabel: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
          AveragePriceText: '',
          maxPriceMsg: '',
          minPriceMsg: '',
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
              division_id: [],
              district_id: [],
              upazila_id: [],
              market_id: [],
              commodity_group_id: 0,
              commodity_sub_group_id: 0,
              commodity_id: 0,
              price_type_id: 0,
              price_date: ''
          },
          stickyHeader: true,
          currentDate: '',
          listItemofyerterday: '',
          districtList: [],
          upazilaList: [],
          marketList: [],
          subgroupList: [],
          commoditySubGroupList: [],
          commodityNameList: [],
          warehouseServiceBaseUrl: warehouseServiceBaseUrl,
          givenDateReports: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          lastWeeks: [],
          lastMonths: [],
          lastYears: [],
          firstTime: true,
          showData: false,
          values_division: [],
          values_district: [],
          values_upazila: [],
          values_market: [],
          filters_division: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_district: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_upazila: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_market: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          options: {
            multi: true,
            groups: false,
            labelName: 'text',
            labelValue: 'value',
            cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : '')
          },
          clickSubmit: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.headerDataExcel()
        this.AveragePriceText = (parseInt(this.search.HighestPrice) + parseInt(this.search.LowestPrice)) / 2
    },
    computed: {
       headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
                headerVal[0] = this.headerExcelDefault.orgName
                headerVal[1] = this.headerExcelDefault.address
                headerVal[2] = this.$t('damReport.comparative_market_price_statement')
                headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.search.division_id)
                headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.search.district_id)
                headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.search.upazila_id)
                headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.search.market_id)
                headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.search.commodity_group_name_en
                headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.search.commodity_sub_group_name_en
                headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.search.commodity_name_en
                headerVal[10] = this.$t('crop_price_config.price_type') + ': ' + this.getPriceTypeName(this.search.price_type_id)
                headerVal[11] = this.$t('crop_price_config.price_date') + ':  ' + dateFormat(this.search.price_date)
              } else {
                headerVal[0] = this.headerExcelDefault.orgNameBn
                headerVal[1] = this.headerExcelDefault.address_bn
                headerVal[2] = this.$t('damReport.comparative_market_price_statement')
                headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.search.division_id)
                headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.search.district_id)
                headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.search.upazila_id)
                headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.search.market_id)
                headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.search.commodity_group_name_bn
                headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.search.commodity_sub_group_name_bn
                headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.search.commodity_name_bn
                headerVal[10] = this.$t('crop_price_config.price_type') + ': ' + this.getPriceTypeName(this.search.price_type_id)
                headerVal[11] = this.$t('crop_price_config.price_date') + ':  ' + dateFormat(this.search.price_date)
              }
        return headerVal
      },
       excelData: function () {
          var serial = 0
          const search = this.search
          const rowData = this.givenDateReports.map((info, index) => {
          serial += 1
          let unit = ' '
          let todayPrice = ' '
          let weakPrice = ' '
          let weakPercentage = ' '
          let monthlyPrice = ' '
          let monthlyPercentage = ' '
          let yearlyPrice = ' '
          let yearlyPercentage = ' '
          if (search.price_type_id === 'Wholesale') {
            if (parseFloat(info.w_lowestPrice) || parseFloat(info.w_highestPrice)) {
              todayPrice = this.$n(parseFloat(info.w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.weak_w_lowestPrice) || parseFloat(info.weak_w_highestPrice)) {
              weakPrice = this.$n(parseFloat(info.weak_w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.weak_w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.w_avgPrice) && parseFloat(info.weak_w_avgPrice)) {
              weakPercentage = this.$n(parseFloat(this.calculatePercentage(info.w_avgPrice, info.weak_w_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.month_w_lowestPrice) || parseFloat(info.month_w_highestPrice)) {
              monthlyPrice = this.$n(parseFloat(info.month_w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.month_w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.w_avgPrice) && parseFloat(info.month_w_avgPrice)) {
              monthlyPercentage = this.$n(parseFloat(this.calculatePercentage(info.w_avgPrice, info.month_w_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.year_w_lowestPrice) || parseFloat(info.year_w_highestPrice)) {
              yearlyPrice = this.$n(parseFloat(info.year_w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.year_w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.w_avgPrice) && parseFloat(info.year_w_avgPrice)) {
              yearlyPercentage = this.$n(parseFloat(this.calculatePercentage(info.w_avgPrice, info.year_w_avgPrice)).toFixed(2))
            }
            unit = this.$i18n.locale === 'bn' ? info.w_unit_name_bn : info.w_unit_name
          } else {
            if (parseFloat(info.r_lowestPrice) || parseFloat(info.r_highestPrice)) {
              todayPrice = this.$n(parseFloat(info.r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.weak_r_lowestPrice) || parseFloat(info.weak_r_highestPrice)) {
              weakPrice = this.$n(parseFloat(info.weak_r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.weak_r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.r_avgPrice) && parseFloat(info.weak_r_avgPrice)) {
              weakPercentage = this.$n(parseFloat(this.calculatePercentage(info.r_avgPrice, info.weak_r_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.month_r_lowestPrice) || parseFloat(info.month_r_highestPrice)) {
              monthlyPrice = this.$n(parseFloat(info.month_r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.month_r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.r_avgPrice) && parseFloat(info.month_r_avgPrice)) {
              monthlyPercentage = this.$n(parseFloat(this.calculatePercentage(info.r_avgPrice, info.month_r_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.year_r_lowestPrice) || parseFloat(info.year_r_highestPrice)) {
              yearlyPrice = this.$n(parseFloat(info.year_r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.year_r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.r_avgPrice) && parseFloat(info.year_r_avgPrice)) {
              yearlyPercentage = this.$n(parseFloat(this.calculatePercentage(info.r_avgPrice, info.year_r_avgPrice)).toFixed(2))
            }
            unit = this.$i18n.locale === 'bn' ? info.unit_name_bn : info.unit_name
          }
            if (this.$i18n.locale === 'en') {
              return {
                'Sl ': serial,
                 'Product name and description': info.commodity_name,
                 Measurement: unit,
                'Today Market Rate': todayPrice,
                'Week Market Rate': weakPrice,
                'Percentage Decrease increase': weakPercentage,
                'Month Market Rate': monthlyPrice,
                'Percentage Decrease  increase': monthlyPercentage,
                'Previous Year Market Rate': yearlyPrice,
                'Percentage Decrease increase ': yearlyPercentage
              }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'পণ্যের নাম ও বিবরণ': info.commodity_name_bn,
                একক: unit,
                'আজকের বাজার দর': todayPrice,
                'গত সপ্তাহের বাজার দর': weakPrice,
                'শতকরা হ্রাস বৃদ্ধি': weakPercentage,
                'গত মাসের বাজার দর': monthlyPrice,
                'শতকরা হ্রাস বৃদ্ধি ': monthlyPercentage,
                'গত বছরের বাজার দর': yearlyPrice,
                'শতকরা হ্রাস  বৃদ্ধি': yearlyPercentage
              }
            }
          })
          return rowData
        },
        priceTypeList: function () {
          return this.$store.state.Portal.agriObj.priceTypeList.filter(item => item.entry_type === 'Market')
        },
        monthList: function () {
          return this.$store.state.Portal.agriObj.monthList
        },
        weekList: function () {
          return this.$store.state.Portal.agriObj.weekList
        },
        divisionList: function () {
          return this.$store.state.Portal.agriObj.divisionList
        },
        groupList () {
          return this.$store.state.Portal.agriObj.commodityGroupList.filter(item => item.status === 1)
        },
        yearList () {
          const yearList = this.$store.state.Portal.agriObj.yearList
          return yearList.map(item => {
            if (this.$i18n.locale === 'en') {
              return { value: item.value, text: item.text_en }
            } else {
              return { value: item.value, text: item.text_bn }
            }
          })
        },
        priceEntryTypeList: function () {
          return this.$store.state.Portal.agriObj.priceEntryTypeList
        },
        timePreiodList: function () {
          return this.$store.state.Portal.agriObj.timePreiodList
        },
        i18 () {
          return this.$i18n.locale
        },
        columns () {
          const labels = [
              { label: this.$t('damReport.product_name_and_description'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.today_market_rate'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.previus_week_market_rate'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.percentage_decrease_increase'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.previus_month_market_rate'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.percentage_decrease_increase'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.previus_year_market_rate'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.percentage_decrease_increase'), class: 'text-center', sortable: true }
            ]
          let keys = []

            if (this.$i18n.locale === 'bn') {
              if (this.search.price_type_id === 'Wholesale') {
                keys = [
                  { key: 'commodity_name_bn' },
                  { key: 'w_unit_name_bn' },
                  { key: 'w_lowestPrice' },
                  { key: 'weak_w_lowestPrice' },
                  { key: 'weak_w_avgPrice' },
                  { key: 'month_w_lowestPrice' },
                  { key: 'month_w_avgPrice' },
                  { key: 'year_w_lowestPrice' },
                  { key: 'year_w_avgPrice' }
                ]
              } else {
                keys = [
                  { key: 'commodity_name_bn' },
                  { key: 'unit_name_bn' },
                  { key: 'r_lowestPrice' },
                  { key: 'weak_r_lowestPrice' },
                  { key: 'weak_r_avgPrice' },
                  { key: 'month_r_lowestPrice' },
                  { key: 'month_r_avgPrice' },
                  { key: 'year_r_lowestPrice' },
                  { key: 'year_r_avgPrice' }
                ]
              }
            } else {
              if (this.search.price_type_id === 'Wholesale') {
                keys = [
                  { key: 'commodity_name' },
                  { key: 'w_unit_name' },
                  { key: 'w_lowestPrice' },
                  { key: 'weak_w_lowestPrice' },
                  { key: 'weak_w_avgPrice' },
                  { key: 'month_w_lowestPrice' },
                  { key: 'month_w_avgPrice' },
                  { key: 'year_w_lowestPrice' },
                  { key: 'year_w_avgPrice' }
                ]
              } else {
                keys = [
                  { key: 'commodity_name' },
                  { key: 'unit_name' },
                  { key: 'r_lowestPrice' },
                  { key: 'weak_r_lowestPrice' },
                  { key: 'weak_r_avgPrice' },
                  { key: 'month_r_lowestPrice' },
                  { key: 'month_r_avgPrice' },
                  { key: 'year_r_lowestPrice' },
                  { key: 'year_r_avgPrice' }
                ]
              }
            }
          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
        },
        loading () {
          return this.$store.state.commonObj.loading
        }
    },
    watch: {
        values_division: function (newVal) {
          if (newVal.length) {
            this.districtList = this.getDistrictList(newVal)
          } else {
            this.districtList = []
          }
        },
        values_district: function (newVal) {
          if (newVal.length) {
            this.upazilaList = this.getUpazilaList(newVal)
          } else {
            this.upazilaList = []
          }
        },
        values_upazila: function (newVal) {
          if (newVal.length) {
            this.marketList = this.getMarketList(newVal)
          } else {
            this.marketList = []
          }
        },
        'search.commodity_group_id': function (newVal, oldVal) {
            this.subgroupList = this.getSubGroupList(newVal)
        },
        'search.commodity_sub_group_id': function (newVal, oldVal) {
            this.commodityNameList = this.getCommodityNameList(newVal)
        }
    },
    methods: {
       headerDataExcel () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      monthCheck (value) {
        return moment(value).format('M')
      },
      pdfExport () {
          const reportTitle = this.$t('damReport.comparative_market_price_statement')
          ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.givenDateReports, this, this.search)
        },
        onChangeFile (e) {
            this.attachmentDemo = e.target.files[0]
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
          Store.commit('mutateCommonProperties', {
            loading: true
          })
          this.showData = true
          const divisionObj = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === this.search.division_id)
          this.search.division_name_en = typeof divisionObj !== 'undefined' ? divisionObj.text_en : ''
          this.search.division_name_bn = typeof divisionObj !== 'undefined' ? divisionObj.text_bn : ''

          const districtListObj = this.$store.state.Portal.agriObj.districtList.find(item => item.value === this.search.district_id)
          this.search.district_name_en = typeof districtListObj !== 'undefined' ? districtListObj.text_en : ''
          this.search.district_name_bn = typeof districtListObj !== 'undefined' ? districtListObj.text_bn : ''

          const upazilaListObj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === this.search.upazila_id)
          this.search.upazila_name_en = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_en : ''
          this.search.upazila_name_bn = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_bn : ''

          const marketListObj = this.$store.state.Portal.agriObj.marketList.find(item => item.value === this.search.market_id)
          this.search.market_name_en = typeof marketListObj !== 'undefined' ? marketListObj.text_en : ''
          this.search.market_name_bn = typeof marketListObj !== 'undefined' ? marketListObj.text_bn : ''

          const commodityGroupListObj = this.$store.state.Portal.agriObj.commodityGroupList.find(item => item.value === this.search.commodity_group_id)
          this.search.commodity_group_name_en = typeof commodityGroupListObj !== 'undefined' ? commodityGroupListObj.text_en : ''
          this.search.commodity_group_name_bn = typeof commodityGroupListObj !== 'undefined' ? commodityGroupListObj.text_bn : ''

          const commoditySubGroupListObj = this.$store.state.Portal.agriObj.commoditySubGroupList.find(item => item.value === this.search.commodity_sub_group_id)
          this.search.commodity_sub_group_name_en = typeof commoditySubGroupListObj !== 'undefined' ? commoditySubGroupListObj.text_en : ''
          this.search.commodity_sub_group_name_bn = typeof commoditySubGroupListObj !== 'undefined' ? commoditySubGroupListObj.text_bn : ''

          const commodityNameListObj = this.$store.state.Portal.agriObj.commodityNameList.find(item => item.value === this.search.commodity_id)
          this.search.commodity_name_en = typeof commodityNameListObj !== 'undefined' ? commodityNameListObj.text_en : ''
          this.search.commodity_name_bn = typeof commodityNameListObj !== 'undefined' ? commodityNameListObj.text_bn : ''

          const marketIds = []
          this.values_market.forEach(item => {
            marketIds.push(parseInt(item.value))
          })
          this.search.market_id = marketIds

          const searchData = {
            division_id: this.search.division_id,
            district_id: this.search.district_id,
            upazila_id: this.search.upazila_id,
            market_id: this.search.market_id,
            commodity_group_id: this.search.commodity_group_id,
            commodity_sub_group_id: this.search.commodity_sub_group_id,
            commodity_id: this.search.commodity_id,
            price_type_id: this.search.price_type_id,
            price_date: this.search.price_date
          }
          const result = await RestApi.postData(agriMarketingServiceBaseUrl, '/crop-price-info/reports/price-report/comparative-commodity-report', searchData)
          if (result.success) {
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            this.givenDateReports = []
            if (result.data) {
              this.getRelationalData(result.data)
            } else {
              this.givenDateReports = []
              Store.commit('mutateCommonProperties', {
                loading: false
              })
              this.firstTime = false
            }
            this.clickSubmit = false
          } else {
            this.givenDateReports = []
            this.firstTime = false
            this.clickSubmit = false
          }
          this.headerDataExcel()
        },
        getRelationalData (data) {
          const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList
          this.givenDateReports = data.givenDateReports
          this.lastWeeks = data.lastWeeks
          this.lastMonths = data.lastMonths
          this.lastYears = data.lastYears
          const list = this.givenDateReports.map(item => {
            const commodityObj = commodityNameList.find(com => com.value === item.commodity_id)
            const lastWeeks = this.lastWeeks.find(com => com.commodity_id === item.commodity_id)
            const commodityWeakData = {
              commodity_name: commodityObj !== 'undefined' ? commodityObj.text_en : '',
              commodity_name_bn: commodityObj !== 'undefined' ? commodityObj.text_bn : '',
              weak_r_highestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.r_highestPrice : '',
              weak_r_lowestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.r_lowestPrice : '',
              weak_w_highestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.w_highestPrice : '',
              weak_w_lowestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.w_lowestPrice : '',
              weak_r_avgPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.r_avgPrice : '',
              weak_w_avgPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.w_avgPrice : ''
            }
            const lastMonths = this.lastMonths.find(com => com.commodity_id === item.commodity_id)
            const commodityMonthData = {
              month_r_highestPrice: typeof lastMonths !== 'undefined' ? lastMonths.r_highestPrice : '',
              month_r_lowestPrice: typeof lastMonths !== 'undefined' ? lastMonths.r_lowestPrice : '',
              month_w_highestPrice: typeof lastMonths !== 'undefined' ? lastMonths.w_highestPrice : '',
              month_w_lowestPrice: typeof lastMonths !== 'undefined' ? lastMonths.w_lowestPrice : '',
              month_r_avgPrice: typeof lastMonths !== 'undefined' ? lastMonths.r_avgPrice : '',
              month_w_avgPrice: typeof lastMonths !== 'undefined' ? lastMonths.w_avgPrice : ''
            }
            const lastYears = this.lastYears.find(com => com.commodity_id === item.commodity_id)
            const commodityYearData = {
              year_r_highestPrice: typeof lastYears !== 'undefined' ? lastYears.r_highestPrice : '',
              year_r_lowestPrice: typeof lastYears !== 'undefined' ? lastYears.r_lowestPrice : '',
              year_w_highestPrice: typeof lastYears !== 'undefined' ? lastYears.w_highestPrice : '',
              year_w_lowestPrice: typeof lastYears !== 'undefined' ? lastYears.w_lowestPrice : '',
              year_r_avgPrice: typeof lastYears !== 'undefined' ? lastYears.r_avgPrice : '',
              year_w_avgPrice: typeof lastYears !== 'undefined' ? lastYears.w_avgPrice : ''
            }
            const rUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_retail)
            const rUnitData = {
              unit_name: typeof rUnitObj !== 'undefined' ? rUnitObj.text_en : '',
              unit_name_bn: typeof rUnitObj !== 'undefined' ? rUnitObj.text_bn : ''
            }
            const WUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_whole_sale)
            const WUnitData = {
              w_unit_name: WUnitObj !== 'undefined' ? WUnitObj.text_en : '',
              w_unit_name_bn: WUnitObj !== 'undefined' ? WUnitObj.text_bn : ''
            }
            return Object.assign({}, item, commodityWeakData, commodityMonthData, commodityYearData, rUnitData, WUnitData)
          })
          this.givenDateReports = list
        },
        getDivisionName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getDistrictList (divisionList) {
          const divisionIds = []
          divisionList.forEach(item => {
            divisionIds.push(parseInt(item.value))
          })
          this.search.division_id = divisionIds
          this.districtList = this.$store.state.Portal.agriObj.districtList.filter(item => divisionIds.includes(item.division_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })
          if (this.values_district.length) {
            const arr = []
            this.values_district.forEach(item => {
              const obj = this.districtList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_district = arr
          }
          return this.districtList
        },
        getDistrictName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.districtList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.districtList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getUpazilaList (districtList) {
          const districtIds = []
          districtList.forEach(item => {
            districtIds.push(parseInt(item.value))
          })
          this.search.district_id = districtIds
          this.upazilaList = this.$store.state.Portal.agriObj.upazilaList.filter(item => districtIds.includes(item.district_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })

          if (this.values_upazila.length) {
            const arr = []
            this.values_upazila.forEach(item => {
              const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_upazila = arr
          }
          return this.upazilaList
        },
        getUpazilaName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getUpazilaNameCustom (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const market = this.$store.state.Portal.agriObj.marketList.find(item => item.upazila_id === parseInt(id))
              if (market !== undefined) {
                const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
                arr.push(obj?.text)
              }
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getMarketList (upazilaList) {
          const upazilaIds = []
          upazilaList.forEach(item => {
            upazilaIds.push(parseInt(item.value))
          })
          this.search.upazila_id = upazilaIds
          this.marketList = this.$store.state.Portal.agriObj.marketList.filter(item => upazilaIds.includes(item.upazila_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })

          if (this.values_market.length) {
            const arr = []
            this.values_market.forEach(item => {
              const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_market = arr
          }
          return this.marketList
        },
        getMarketName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.marketList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.marketList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getNames (list) {
          const arr = []
          list.forEach(item => {
            arr.push(item?.text)
          })
          const str = arr.join(', ')
          return str.length > 50 ? str.substring(0, 40) + '..' : str
        },
        getPriceTypeList: function (entryType) {
            const dataList = this.$store.state.Portal.agriObj.priceTypeList.filter(item => item.entry_type === entryType)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getSubGroupList (groupId = null) {
            const subgroupList = this.$store.state.Portal.agriObj.commoditySubGroupList.filter(item => item.status === 1)

            if (groupId) {
                return subgroupList.filter(subgroup => subgroup.commodity_group_id === groupId)
            }
            return subgroupList
        },
        getCommodityNameList (groupId = null) {
            const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList.filter(item => item.status === 1)

            if (groupId) {
                return commodityNameList.filter(commodityName => commodityName.commodity_sub_group_id === groupId)
            }
            return commodityNameList
        },
        getPriceTypeName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.priceTypeList.find(item => item.text_en === id)
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.priceTypeList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        calculatePercentage (oldAvg, newAvg) {
          const diffAmt = parseFloat(oldAvg) - parseFloat(newAvg)
          const totalPercentage = (100 * diffAmt) / parseFloat(newAvg)
          return totalPercentage
        }
    }
}
</script>
